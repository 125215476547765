@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "Gilroy-400";
  src: url("./fonts/Gilroy-Regular.ttf");
}

@font-face {
  font-family: "Gilroy-500";
  src: url("./fonts/Gilroy-Medium.ttf");
}

@font-face {
  font-family: "Gilroy-600";
  src: url("./fonts/Gilroy-SemiBold.ttf");
}

@font-face {
  font-family: "Gilroy-700";
  src: url("./fonts/Gilroy-Bold.ttf");
}


/* badges */
.pending-badge {
  @apply  text-[#FFB648] bg-transparent md:bg-[#FFAC321A] text-xs rounded-[100px] px-4 py-1 font-Gilroy400 text-ellipsis overflow-hidden;
}

.success-badge {
  @apply  text-[#9CC031] bg-transparent md:bg-[#9CC0311A] whitespace-nowrap text-xs rounded-[100px] px-4 py-1 font-Gilroy400 text-ellipsis overflow-hidden;
}

.failed-badge {
  @apply  text-[#E41414] bg-transparent md:bg-[#EB001B26] text-xs rounded-[100px] px-4 py-1 font-Gilroy400 text-ellipsis overflow-hidden;
}


/* react pin input styles */
.pincode-input-container .pincode-input-text{
  border-radius: 8px;
  margin-right: 20px !important;
  appearance: none;
}


.pincode-input-container .pincode-input-text:last-child{
  margin-right: 0px !important;
}



/* Style the <option> elements */
select option {
  background-color: #262422 !important; /* Set the background color */
  color: #fff; /* Set the text color */
}

/* Style the <option> elements on hover */
select option:hover {
  background-color: #F7F8FC !important; /* Change background color on hover */
  color: #F1F5F6; /* Change text color on hover */
}


/* radio buttons */
input[type="radio"] {
  /* The native appearance is hidden */
  appearance: none;
  -webkit-appearance: none;

  /* For a circular appearance we need a border-radius. */
  border-radius: 50%;

  /* The background will be the radio dot's color. */
  background: #2D2A28;

  /* The border will be the spacing between the dot and the outer circle */
  border: 3px solid #FFF;

  /* And by creating a box-shadow with no offset and no blur, we have an outer circle */
  box-shadow: 0 0 0 1px #2D2A28;
  
}

input[type="radio"]:checked {
  box-shadow: 0 0 0 1px #407BFF;
  background-color: #407BFF;
}

/* react data tables */
.rdt_Table{
  background-color: transparent !important;
}
.rdt_TableHeader{
  background: transparent !important;
  color: #F1F5F6 !important;
  font-family: 'Gilroy-700';
  font-size: 18px !important;
}

.rdt_TableHeadRow{
  color: #F1F5F6 !important;
  background-color: #1D1C22 !important;
  font-size: 14px !important;
  font-family: 'Gilroy-500' !important;
}

.rdt_TableRow{
  background-color: #262422 !important;
  color: #F1F5F6 !important;
  font-size: 12px !important;
  font-family: 'Gilroy-400' !important;
}

.rdt_Pagination{
  background-color: transparent !important;
  color: #F1F5F6 !important;
}

.rdt_Pagination button{
  color: #F1F5F6 !important;
  fill: #F1F5F6 !important;
}

.rdt_TableBody{
  background-color: transparent !important;
}
